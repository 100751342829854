import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './AttributePage.style';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { AttributeRow } from '@app/interfaces/attribute.interface';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { notificationController } from '@app/controllers/notificationController';
import { DeleteAttribute, fetchAttributeByUuid, UpdateAttribute } from '@app/api/Attribute.api';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { CategoryModel } from '@app/domain/CategoryModel';
import { Categories } from '@app/api/Category.api';
import { categoryOption } from './NewAttributePage';


const UpdateAttributePage: React.FC = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [initValues, setInitValues] = useState({ title: '', category: { label: '', value: 0 } });
  const [inputs, setInputs] = useState<AttributeRow[]>([{ title: '', id: 0, items: [{ title: '', id: 0 }] }]);
  const navigate = useNavigate();
  const location = useLocation();
  const [destroyModal, setDestroyModal] = useState(false);
  const [id, setId] = useState<number | undefined>(0);
  const [categories, setCategories] = useState<CategoryModel[]>([]);

  const handleOpenDestroyModal = (id: number | undefined) => {
    setDestroyModal(true);
    setId(id);
  };

  const fetchCategories = useCallback(() => {
    Categories({ type: 'product', page: 1 })
      .then((response) => {
        if (response.success) {
          setCategories(response.data.categories);
        } else {
          notificationController.error({ message: response.message });
        }
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
      });
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
      const uuid = window.location.pathname.split('/').pop();
      fetchAttributeByUuid(uuid!).then((data) => {
        setInitValues({ title: data.data.title, category: { label: data.data.category.title, value: data.data.category.id }});
        const _inputs: AttributeRow[] = data.data.items.map((item: any) => ({
          title: item.title,
          id: item.id,
          items: item.items.map((subItem: any) => ({
            title: subItem.title,
            id: subItem.id,
          })),
        }));
        setInputs(_inputs);
      });
  }, []);

  const handleSubmitUpdate = (values: any) => {
    setLoading(true);

    UpdateAttribute(location.state.id, { title: values.title, inputs, category: initValues.category})
      .then((result) => {
        if (result.success) {
          notificationController.success({ message: result.message });
          navigate('/products/attributes');
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  const handleAddRow = (index: number) => {
    const rows = [...inputs];
    rows[index].items?.push({ title: '', id: 0 });
    setInputs(rows);
  };

  const handleAddRow2 = () => {
    setInputs([...inputs, { title: '', id: 0, items: [{ title: '', id: 0 }] }]);
  };

  const removeRowItem = (index: number) => {
    const rows = [...inputs];
    if (rows[index].id === 0) {
      rows.splice(index, 1);
      setInputs(rows);
    } else {
      handleOpenDestroyModal(rows[index].id);
    }
  };

  const removeRowSubItem = (index: number, subIndex: number) => {
    const rows = [...inputs];

    if (rows[index].items![subIndex].id === 0 || rows[index].items![subIndex].id === undefined) {
      rows[index].items?.splice(subIndex, 1);
      setInputs(rows);
    } else {
      handleOpenDestroyModal(rows[index].items![subIndex].id);
    }
  };

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const list = [...inputs];

    list[index].title = value;
    setInputs(list);
  };

  const handleChangeSub = (index: number, subIndex: string | number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const list = [...inputs];

    list[index].items![subIndex].title = value;
    setInputs(list);
  };

  const handleSubmitDelete = () => {
    setLoading(true);


    DeleteAttribute(id, location.state.id)
      .then((result) => {
        if (result.success) {
          notificationController.success({ message: result.message });

          const _inputs: AttributeRow[] = [];
          result.data.items.forEach(
            (input: { items: { title: string; id: number }[]; title: string; id: number }, index: number) => {
              const _subInputs: AttributeRow[] = [];
              input.items.forEach((subInput: { title: string; id: number }, subIndex: number) => {
                _subInputs[subIndex] = {
                  title: subInput.title,
                  id: subInput.id,
                };
              });

              _inputs[index] = {
                title: input.title,
                id: input.id,
                items: _subInputs,
              };
            },
          );
          setInputs(_inputs);

          setDestroyModal(false);
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };
  

  return (
    <>
      {initValues.title && (
        <S.Card title={t('attributes.update-attribute')} padding="1.25rem 1.25rem 0">
          <BaseForm
            style={{ width: '70%', margin: '0 auto 0 auto' }}
            layout="vertical"
            onFinish={handleSubmitUpdate}
            requiredMark="optional"
            initialValues={initValues}
          >
            <S.FormItem
              name="category" // Connect the form field to the "category" name
              label={t('products.category')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.Select
                placeholder={t('products.selectCategory')}
                options={categories.map((category) => ({
                  label: category.title,
                  value: category.id,
                }))}
                onChange={(value, option) =>
                  setInitValues({ ...initValues, category: { label: (option as categoryOption).label, value: value as number } })
                }
              />
            </S.FormItem>
            <S.FormItem
              name="title"
              label={t('attributes.title')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.FormInput
                placeholder={t('categories.title')}
                onChange={(target) => setInitValues({ ...initValues, title: target.target.value })}
              />
            </S.FormItem>

            {inputs.map((input, index) => (
              <BaseCol style={{ marginLeft: '50px' }}>
                <S.FormItem
                  label={t('attributes.title')}
                  rules={[{ required: true, message: t('common.requiredField') }]}
                >
                  <S.InputsWrapper>
                    <S.FormInput
                      value={input.title}
                      placeholder={t('categories.title')}
                      onChange={(event) => handleChange(index, event)}
                    />
                    <S.SubmitButton
                      style={{ width: '50%' }}
                      type="primary"
                      htmlType="button"
                      onClick={() => handleAddRow(index)}
                    >
                      {t('attributes.add')}
                    </S.SubmitButton>
                    <S.SubmitButton
                      style={{ width: '50%' }}
                      danger={true}
                      type="primary"
                      htmlType="button"
                      onClick={() => removeRowItem(index)}
                    >
                      {t('attributes.remove')}
                    </S.SubmitButton>
                  </S.InputsWrapper>
                </S.FormItem>

                {input.items?.map((_input, _index) => (
                  <BaseCol style={{ marginLeft: '100px' }}>
                    <S.FormItem
                      label={t('attributes.title')}
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <S.InputsWrapper>
                        <div style={{ width: '100%' }}>
                          <S.FormInput
                            value={_input.title}
                            placeholder={t('categories.title')}
                            onChange={(event) => handleChangeSub(index, _index, event)}
                          />
                        </div>

                        <S.SubmitButton
                          style={{ width: '50%' }}
                          type="primary"
                          danger={true}
                          htmlType="button"
                          onClick={() => removeRowSubItem(index, _index)}
                        >
                          {t('attributes.remove')}
                        </S.SubmitButton>
                      </S.InputsWrapper>
                    </S.FormItem>
                  </BaseCol>
                ))}
              </BaseCol>
            ))}

            <S.InputsWrapper>
              <S.SubmitButton style={{ width: '50%' }} type="primary" htmlType="button" onClick={handleAddRow2}>
                {t('attributes.add')}
              </S.SubmitButton>
              <S.SubmitButton type="ghost" htmlType="submit" loading={loading}>
                {t('common.submit')}
              </S.SubmitButton>
            </S.InputsWrapper>
          </BaseForm>
        </S.Card>
      )}

      <BaseModal
        title={t('attributes.delete')}
        visible={destroyModal}
        centered
        size="large"
        onOk={handleSubmitDelete}
        onCancel={() => setDestroyModal(false)}
        cancelText={t('common.close')}
        okText={t('attributes.delete')}
      >
        <p>{t('attributes.delete-item-sure')}</p>
      </BaseModal>
    </>
  );
};
export default UpdateAttributePage;
