import React from 'react';
import * as S from '../SingleProductPage.style';
import { ProductFormData } from '@app/constants/productDetails';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  formData: ProductFormData;
  onChange: (updatedData: Partial<ProductFormData>) => void;
}

const GeneralInfoForm = ({ formData, onChange }: Props) => {
  const { t } = useTranslation();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    onChange({ [name]: value });
  };

  return (
    <>
      <S.FormItem>
        <label>{t('products.title')}</label>
        <S.FormInput name="title" defaultValue={formData.title} onChange={handleInputChange} />
      </S.FormItem>
      <S.FormItem>
        <label>{t('products.shortDescription')}</label>
        <Input.TextArea name="shortDescription" defaultValue={formData.shortDescription} onChange={handleInputChange} />
      </S.FormItem>
      <S.FormItem>
        <label>{t('products.description')}</label>
        <Input.TextArea name="description" defaultValue={formData.description} onChange={handleInputChange} />
      </S.FormItem>
      {formData.sku && (
        <S.FormItem>
          <label>{t('products.sku')}</label>
          <S.FormInput name="sku" defaultValue={formData.sku} disabled onChange={handleInputChange} />
        </S.FormItem>
      )}
    </>
  );
};

export default GeneralInfoForm;
