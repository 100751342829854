import React from 'react';
import { TreeSelect, Input } from 'antd';
import * as S from '../SingleProductPage.style';
import { BrandShowModel, CategoryOptionsModel, CategoryShowModel, ProductFormData, Tags } from '@app/constants/productDetails';
import { PRODUCT_STATUS } from '@app/constants/enums/product.status';
import { useTranslation } from 'react-i18next';
import { BrandData } from '@app/interfaces/brand.interface';

interface Props {
  formData: ProductFormData;
  onChange: (updatedData: Partial<ProductFormData>) => void;
  categoryOptions: CategoryOptionsModel[];
  handleTagInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTagInputKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleTagRemove: (id: number) => void;
  tagInput: string;
  brands: BrandData[];
  handleCategorySelection: (value: CategoryShowModel[]) => void;
  handleBrandChange: (value: BrandShowModel) => void;
}

const AdditionalInfoForm = ({
  formData,
  onChange,
  categoryOptions,
  handleTagInputChange,
  handleTagInputKeyDown,
  handleTagRemove,
  tagInput,
  brands,
  handleCategorySelection,
  handleBrandChange,
}: Props) => {
  const { t } = useTranslation();

  

  const handleInputChange = (name: string, value: any) => {
    if (name.startsWith('prices.0.')) {
      const fieldName = name.split('.')[2]; // Extract `price` or `stock`
      const updatedPrices = [...(formData.prices || [{}])]; // Ensure prices array exists
      updatedPrices[0] = { ...updatedPrices[0], [fieldName]: value }; // Update the specific field
      onChange({ prices: updatedPrices }); // Update prices array in the formData
    } else {
      onChange({ [name]: value }); // Handle other fields normally
    }
  };

  return (
    <>
      <S.FormItem>
        <label>{t('products.status')}</label>
        <S.Select
          value={formData.status}
          onChange={(value: unknown) => handleInputChange('status', value)}
          options={[
            { label: t('common.active'), value: PRODUCT_STATUS.ACTIVE },
            { label: t('common.pending'), value: PRODUCT_STATUS.PENDING },
            { label: t('common.decline'), value: PRODUCT_STATUS.DECLINE },
          ]}
        />
      </S.FormItem>
      <S.FormItem>
        <label>{t('products.categories')}</label>
        <TreeSelect
          treeData={categoryOptions} // Filtered categories for display  
          treeCheckable={true}
          treeCheckStrictly={true}
          placeholder="Select Categories"
          value={(formData.categories as CategoryShowModel[]).map((cat) => cat.value)}
          onChange={(value) => handleCategorySelection(value as unknown as CategoryShowModel[])}
          filterTreeNode={(search, item) => {
            return (item.title as string)?.toLowerCase().indexOf(search.toLowerCase()) >= 0;
          }}
          style={{ width: '100%' }}
        />
      </S.FormItem>
      <S.FormItem>
        <label>{t('products.tags')}</label>
        <div>
          {(formData.tags as Tags[]).map((tag) => (
            <S.Tag key={tag.id}>
              {tag.name}
              <span className="close-icon" onClick={() => handleTagRemove(tag.id)}>
                ×
              </span>
            </S.Tag>
          ))}
          <Input value={tagInput} onChange={handleTagInputChange} onKeyDown={handleTagInputKeyDown} />
        </div>
      </S.FormItem>
      <S.FormItem>
        <label>{t('products.brand')}</label>
        <S.Select
          value={formData.brand}
          onChange={(value: unknown) =>
            handleBrandChange({
              value,
              label: brands.find((brand) => brand.index === (value as number))?.title,
            } as BrandShowModel)
          }
          options={brands.map((brand, index) => ({
            label: brand.title,
            value: brand.index,
          }))}
        />
      </S.FormItem>
      <S.FormItem>
        <label>{t('products.prices')}</label>
        <div className='price'>
          <label>{t('products.price')}</label>
          <S.FormInput
            name="prices.0.price" // Key for updating the price in index 0
            value={formData.prices?.[0]?.price || ''} // Safely access price
            onChange={(e) => handleInputChange('prices.0.price', e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <label>{t('products.stock')}</label>
          <S.FormInput
            name="prices.0.stock" // Key for updating the stock in index 0
            value={formData.prices?.[0]?.stock || ''} // Safely access stock
            onChange={(e) => handleInputChange('prices.0.stock', e.target.value)}
          />
        </div>
      </S.FormItem>
    </>
  );
};

export default AdditionalInfoForm;
