import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './InvoicePage.style';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { SearchUser, UserAddress } from '@app/api/User.api';
import { notificationController } from '@app/controllers/notificationController';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { UserModal } from '@app/domain/User.model';
import {
  UserAddressData,
  UserAddressTableColumns,
  UserSearchData,
  UserSearchTableColumns,
} from '@app/interfaces/user.interface';
import { EditableCell } from './EditableCell';
import { InquiryProductData, InquiryProductTableColumns } from '@app/interfaces/inquiry.interface';
import { ProductSearchRequest, SearchProduct } from '@app/api/Product.api';
import { InquiryCreateRequest, StoreInquiry } from '@app/api/Inquiry.api';
import { useNavigate } from 'react-router-dom';
import { UserAddressModel } from '@app/domain/UserAddresssModel';
import { PreInvoiceCreateRequest, StorePreInvoice } from '@app/api/Invoice.api';
import { useLocation } from 'react-router';

export let initValues = {
  title: '',
  user: '',
  address: '',
  totalPrice: '',
  shippingCost: '',
  discount: '',
  vat: '',
  status: 'pre_invoice',
  products: [{ title: '', price: '', discount: '', quantity: 1, description: '' }],
  description: '',
};

const NewImportInvoicePage: React.FC = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserSearchData[]>([]);
  const [addresses, setAddresses] = useState<UserAddressData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [selectedAddressId, setSeletedAddressId] = useState<number>(0);
  const [products, setProducts] = useState<InquiryProductData[]>([
    {
      index: 0,
      id: 0,
      title: '',
      sku: '',
      quantity: 1,
      price: 0,
      discount: 0,
      description: '',
      action: (
        <BaseSpace>
          <BaseButton type="primary" onClick={() => handleCheckProduct()}>
            {t('common.check')}
          </BaseButton>
          <BaseButton type="ghost" onClick={() => handleAddNewProduct()}>
            {t('common.add')}
          </BaseButton>
        </BaseSpace>
      ),
    },
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  const [productsForm] = BaseForm.useForm();
  const handleAddNewProduct = async () => {
    const row = (await productsForm.validateFields()) as InquiryProductData;

    setProducts((products) => [
      ...products,
      {
        index: products.length,
        title: row.title,
        id: row.id,
        sku: row.sku,
        quantity: row.quantity,
        discount: 0,
        price: row.price,
        description: row.description,
        action: (
          <BaseSpace>
            <BaseButton type="ghost" danger={true} onClick={() => handleRemoveProduct(products.length)}>
              {t('common.remove')}
            </BaseButton>
          </BaseSpace>
        ),
      },
    ]);
    setEditingKey(0);

    row.title = '';
    row.sku = '';
    row.quantity = 1;
    row.price = 0;
    row.description = '';
    row.discount = 0;
    await productsForm.setFieldsValue(row);
  };

  const handleCheckProduct = async () => {
    const row = (await productsForm.validateFields()) as InquiryProductData;
    const search: ProductSearchRequest = { title: row.title, sku: row.sku };
    SearchProduct(search)
      .then(async (result) => {
        console.log(result.data[0]);
        if (result.success) {
          if (result.data[0]) {
            row.title = result.data[0].title;
            row.sku = result.data[0].sku;
            row.price = result.data[0].prices.length >= 1 ? parseFloat(result.data[0].prices[0].price) : 0;
            row.id = result.data[0].prices.length >= 1 ? result.data[0].prices[0].id : 0;

            await productsForm.setFieldsValue(row);
          } else {
            notificationController.error({ message: t('inquiries.product-not-found') });
          }
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((error) => {});
  };

  const handleRemoveProduct = (index: number) => {
    const rows = [...products];
    rows.splice(index, 1);
    setProducts(rows);
  };

  const [editingKey, setEditingKey] = useState(0);
  const isEditing = (record: InquiryProductData) => record.index === editingKey;
  const mergedColumns = InquiryProductTableColumns(t).map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: InquiryProductData) => ({
        record,
        inputType: col.dataIndex === 'quantity' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    const userSearchRequest: { email: string } = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      email: location.state.inquiry.email,
    };
    setLoading(true);
    setUsers([]);

    SearchUser(userSearchRequest)
      .then((result) => {
        if (result.success) {
          result.data.map((user, index) => {
            setUsers((users) => [
              ...users,
              {
                index: user.id,
                firstname: user.firstName,
                lastname: user.lastName,
                email: user.email,
                action: <BaseSpace></BaseSpace>,
              },
            ]);
          });

          setSelectedUserId(result.data[0].id);
        }
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
        setLoading(false);
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    UserAddress(selectedUserId)
      .then((result) => {
        if (result.success) {
          result.data.map((address, index) => {
            setAddresses((addresses) => [
              ...addresses,
              {
                index: address.id,
                firstname: address.firstName,
                lastname: address.lastName,
                address: address.address,
                city: address.city.title,
                action: (
                  <BaseSpace>
                    <BaseButton type="ghost" onClick={() => handleSelectAddress(address)}>
                      {t(selectedAddressId !== 0 ? 'common.selected' : 'common.select')}
                    </BaseButton>
                  </BaseSpace>
                ),
              },
            ]);
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error({ message: error.message });
        setLoading(false);
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    location.state.inquiry.products.map((product, index) => {
      setProducts((products) => [
        ...products,
        {
          id: product.id,
          index: product.id,
          title: product.title,
          sku: product.code,
          quantity: product.quantity,
          price: product.price ? product.price : product.uPrice,
          discount: product.discount,
          description: product.description,
          action: <></>,
        },
      ]);
    });

    initValues = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      totalPrice: location.state.inquiry.totalPrice,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      shippingCost: location.state.inquiry.shippingCost,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      discount: location.state.inquiry.discount,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      vat: location.state.inquiry.vat,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      description: location.state.inquiry.description,
    };
  }, []);

  const handleSubmitCreate = (values: any) => {
    setLoading(true);

    const prices: {
      id: number;
      price: number;
      discount: number;
      quantity: number;
      description: string;
      sku: string;
      title: string;
    }[] = [];
    products.map((product, index) => {
      prices.push({
        id: product.id,
        price: product.price,
        discount: product.discount,
        quantity: product.quantity,
        description: product.description,
        sku: product.sku,
        title: product.title,
      });
    });

    const createRequest: PreInvoiceCreateRequest = {
      address: selectedAddressId,
      user: selectedUserId,
      discount: values.discount,
      shippingCost: values.shippingCost,
      vat: values.vat,
      status: values.status,
      description: values.description,
      prices: prices,
      version: '1',
      invoice: 0,
    };
    StorePreInvoice(createRequest)
      .then((result) => {
        if (result.success) {
          notificationController.success({ message: t(result.message) });
          navigate('/pre-invoices');
        } else {
          notificationController.error({ message: t(result.message) });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  const handleSelectAddress = (address: UserAddressModel) => {
    setSeletedAddressId(address.id);
  };

  return (
    <>
      <S.Card title={t('invoices.new-pre-invoice')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <BaseForm
            style={{ width: '100%', margin: '0 auto 0 auto' }}
            layout="vertical"
            onFinish={handleSubmitCreate}
            requiredMark="optional"
            initialValues={initValues}
          >
            <S.Table scroll={{ x: 800 }} columns={UserSearchTableColumns(t)} dataSource={users} loading={loading} />
            <S.Table
              scroll={{ x: 800 }}
              columns={UserAddressTableColumns(t)}
              dataSource={addresses}
              loading={loading}
            />
            <S.InputsWrapper>
              <S.FormItem
                style={{ width: '100%' }}
                name="discount"
                label={t('invoices.discount')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <S.FormInput
                  placeholder={t('invoices.discount')}
                  onChange={(target) => (initValues.discount = target.target.value)}
                />
              </S.FormItem>
              <S.FormItem
                style={{ width: '100%' }}
                name="shippingCost"
                label={t('invoices.shippingCost')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <S.FormInput
                  placeholder={t('invoices.shippingCost')}
                  onChange={(target) => (initValues.shippingCost = target.target.value)}
                />
              </S.FormItem>
            </S.InputsWrapper>
            <S.InputsWrapper>
              <S.FormItem
                style={{ width: '100%' }}
                name="vat"
                label={t('invoices.vat')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <S.FormInput
                  placeholder={t('inquiries.vat')}
                  onChange={(target) => (initValues.vat = target.target.value)}
                />
              </S.FormItem>
              <S.FormItem
                style={{ width: '100%' }}
                name="status"
                label={t('inquiries.status')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <BaseSelect width={120} onChange={(value: any) => (initValues.status = value)}>
                  <Option value="pre_invoice">{t('invoices.preInvoice')}</Option>
                </BaseSelect>
              </S.FormItem>
            </S.InputsWrapper>
            <S.FormItem
              style={{ width: '100%' }}
              name="description"
              label={t('invoices.description')}
              rules={[{ required: false, message: t('common.requiredField') }]}
            >
              <S.FormInputLong
                rows={10}
                placeholder={t('invoices.description')}
                onChange={(target) => (initValues.description = target.target.value)}
              />
            </S.FormItem>

            <BaseForm form={productsForm} component={false}>
              <S.Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                dataSource={products}
                columns={mergedColumns}
                rowClassName="editable-row"
                loading={loading}
                scroll={{ x: 800 }}
              />
            </BaseForm>

            <S.InputsWrapper style={{ marginTop: '50px' }}>
              <S.SubmitButton type="primary" htmlType="submit" loading={loading}>
                {t('common.submit')}
              </S.SubmitButton>
            </S.InputsWrapper>
          </BaseForm>
        </S.TablesWrapper>
      </S.Card>
    </>
  );
};

export default NewImportInvoicePage;
