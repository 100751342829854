import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './InvoicePage.style';
import { SearchUser, UserAddress } from '@app/api/User.api';
import { notificationController } from '@app/controllers/notificationController';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { UserModal } from '@app/domain/User.model';
import { UserSearchData, UserSearchTableColumns } from '@app/interfaces/user.interface';
import { InquiryData, InquiryTableColumns, InquiryVersionTableColumns } from '@app/interfaces/inquiry.interface';
import { SearchInquiry, Versions } from '@app/api/Inquiry.api';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { InquiryModel } from '@app/domain/Inquiry.model';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

const ImportInquiryPage: React.FC = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserSearchData[]>([]);
  const [inquiries, setInquiries] = useState<InquiryData[]>([]);
  const [inquiryVersions, setInquiryVersions] = useState<InquiryData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserModal>({
    avatar: '',
    createdAt: new Date(),
    deletedAt: new Date(),
    email: '',
    firstName: '',
    id: 0,
    isExpert: false,
    isSeller: false,
    lastName: '',
    mobile: '',
    status: '',
    updatedAT: new Date(),
    uuid: '',
  });
  const [inquiry, setInquiry] = useState<InquiryModel>();
  const [versionModal, setVersionModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSelectUser = (user: UserModal) => {
    setInquiries([]);
    SearchInquiry({
      email: user.email,
      code: '',
      fromDate: '',
      toDate: '',
    })
      .then((result) => {
        if (result.success) {
          result.data.map((inquiry, index) => {
            setInquiries((inquiries) => {
              return [
                ...inquiries,
                {
                  id: inquiry.id,
                  index: inquiry.id,
                  code: inquiry.code,
                  status: inquiry.status,
                  version: inquiry.version,
                  createdAt: moment(inquiry.createdAt).format('Y-M-D h:i:s'),
                  totalPrice: inquiry.totalPrice,
                  user: inquiry.user ? inquiry.user.email : inquiry.email,
                  actions: (
                    <BaseSpace>
                      <BaseButton type="ghost" onClick={() => handleSelectInquiry(inquiry)}>
                        {t('common.select')}
                      </BaseButton>
                      <BaseButton type="ghost" onClick={() => handleOpenVersions(inquiry)}>
                        {t('inquiries.versions')}
                      </BaseButton>
                    </BaseSpace>
                  ),
                },
              ];
            });
            setUser({
              avatar: '',
              createdAt: user.createdAt,
              deletedAt: user.deletedAt,
              firstName: '',
              isExpert: false,
              isSeller: false,
              lastName: '',
              mobile: '',
              status: '',
              updatedAT: user.updatedAT,
              uuid: '',
              id: user.id,
              email: user.email,
            });
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  const handleOpenVersions = (inquiry: InquiryModel) => {
    setInquiryVersions([]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Versions(inquiry.id)
      .then((result) => {
        if (result.success) {
          result.data.map((version, index) => {
            setInquiryVersions((versions) => {
              return [
                ...versions,
                {
                  id: version.id,
                  index: version.id,
                  code: version.code,
                  status: version.status,
                  version: version.version,
                  totalPrice: version.totalPrice,
                  createdAt: moment(version.createdAt).format('Y-M-D h:i:s'),
                  actions: (
                    <BaseSpace>
                      <BaseButton type="ghost" onClick={() => handleSelectInquiry(version)}>
                        {t('common.select')}
                      </BaseButton>
                    </BaseSpace>
                  ),
                },
              ];
            });
          });

          setVersionModal(true);
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  const handleSelectInquiry = (inquiry: InquiryModel) => {
    setInquiry(inquiry);

    navigate('/pre-invoices/new/inquiry', { state: { inquiry: inquiry, user: user } });
  };

  const searchUser = (email: string) => {
    const userSearchRequest: { email: string } = {
      email: email,
    };
    setLoading(true);
    setUsers([]);

    if (email !== '') {
      SearchUser(userSearchRequest)
        .then((result) => {
          if (result.success) {
            result.data.map((user, index) => {
              setUsers((users) => [
                ...users,
                {
                  index: user.id,
                  firstname: user.firstName,
                  lastname: user.lastName,
                  email: user.email,
                  action: (
                    <BaseSpace>
                      <BaseButton type="ghost" onClick={() => handleSelectUser(user)}>
                        {t('common.select')}
                      </BaseButton>
                    </BaseSpace>
                  ),
                },
              ]);
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          notificationController.error({ message: error.message });
          setLoading(false);
        });
    } else setLoading(false);
  };

  return (
    <>
      <S.Card title={t('invoices.new-pre-invoice')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <S.InputsWrapper>
            <S.FormItem
              style={{ width: '100%' }}
              name="user"
              label={t('invoices.searchUserByEmail')}
              rules={[{ required: false, message: t('common.requiredField') }]}
            >
              <S.FormInput placeholder={t('invoices.email')} onChange={(target) => searchUser(target.target.value)} />
            </S.FormItem>
          </S.InputsWrapper>
          <S.Table scroll={{ x: 800 }} columns={UserSearchTableColumns(t)} dataSource={users} loading={loading} />
          <S.Table scroll={{ x: 800 }} columns={InquiryTableColumns(t)} dataSource={inquiries} loading={loading} />
        </S.TablesWrapper>
      </S.Card>

      <BaseModal
        title={t('inquiries.versions')}
        visible={versionModal}
        centered
        size="large"
        onOk={() => setVersionModal(false)}
        onCancel={() => setVersionModal(false)}
        cancelText={t('common.close')}
        okText={''}
      >
        <S.Table
          scroll={{ x: 800 }}
          columns={InquiryVersionTableColumns(t)}
          dataSource={inquiryVersions}
          loading={loading}
        />
      </BaseModal>
    </>
  );
};

export default ImportInquiryPage;
