import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AttributePage from '@app/pages/Products/attributes/AttributePage';
import * as S from './VariationPage.style';
import { TablePagination } from '@app/interfaces/table.interface';
import { useNavigate } from 'react-router-dom';
import { VariationData, VariationRaw, VariationTableColumns } from '@app/interfaces/Variation.interface';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { DeleteMainVariation, DeleteVariation, Variations } from '@app/api/Variation.api';
import { VariationModel } from '@app/domain/Variation.model';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

const VariationPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [variations, setVariations] = useState<VariationData[]>([]);
  const [pagination, setPagination] = useState<TablePagination>({ current: 1, pageSize: 10, total: 1 });
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [variation, setVariation] = useState<VariationModel>();

  const handleOpenVariation = (variations: VariationModel) => {
    navigate('/products/variations/' + variations.uuid, { state: variations });
  };

  const handleDeleteVariation = (variation: VariationModel) => {
    setDeleteModal(true);
    setVariation(variation);
  };

  const getVariations = () => {
    setVariations([]);
    Variations({ page: pagination.current })
      .then((result) => {
        if (result.success) {
          result.data.variations.map((variation, index) => {
            setVariations((variations) => [
              ...variations,
              {
                index: variation.id,
                name: variation.name,
                type: variation.type,
                actions: (
                  <BaseSpace>
                    <BaseButton type="ghost" onClick={() => handleOpenVariation(variation)}>
                      {t('common.update')}
                    </BaseButton>
                    <BaseButton danger={true} type="ghost" onClick={() => handleDeleteVariation(variation)}>
                      {t('common.delete')}
                    </BaseButton>
                  </BaseSpace>
                ),
              },
            ]);
          });

          setPagination({
            current: pagination.current,
            pageSize: 10,
            total: result.data.count,
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  useEffect(() => {
    getVariations();
  }, []);

  const handleTableChange = (pagination: TablePagination) => {
    setPagination({
      current: pagination.current++,
      pageSize: pagination.pageSize,
      total: pagination.total,
    });
    getVariations();
  };

  const handleSubmitDelete = () => {
    setLoading(true);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    DeleteMainVariation(variation.id)
      .then((result) => {
        if (result.success) {
          notificationController.success({ message: result.message });

          getVariations();
          setDeleteModal(false);
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  return (
    <>
      <S.Card title={t('variations.variations')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <S.Table
            scroll={{ x: 800 }}
            columns={VariationTableColumns(t)}
            dataSource={variations}
            loading={loading}
            pagination={pagination}
            onChange={() => handleTableChange(pagination)}
          />
        </S.TablesWrapper>
      </S.Card>

      <BaseModal
        title={t('variations.delete')}
        visible={deleteModal}
        centered
        size="large"
        onOk={handleSubmitDelete}
        onCancel={() => setDeleteModal(false)}
        cancelText={t('common.close')}
        okText={t('variations.delete')}
      >
        <p>{t('variations.delete-item-sure')}</p>
      </BaseModal>
    </>
  );
};
export default VariationPage;
