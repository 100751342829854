import { httpApi } from '@app/api/http.api';
import { InquiryModel } from '@app/domain/Inquiry.model';

export interface InquiryCreateRequest {
  email?: string;
  user?: number;
  discount: string;
  shippingCost: string;
  var: string;
  status: string;
  description: string;
  prices: Array<{
    id: number;
    price: number;
    discount: number;
    quantity: number;
    description: string;
    sku: string;
    title: string;
  }>;
}

export interface InquirySearchRequest {
  email?: string;
  code?: string;
  status?: string;
  fromDate?: string;
  toDate?: string;
}

export interface InquiryVersionCreateRequest {
  email?: string;
  inquiry: number;
  version: string;
  discount: string;
  shippingCost: string;
  var: string;
  status: string;
  description: string;
  prices: Array<{
    id: number;
    price: number;
    discount: number;
    quantity: number;
    description: string;
    sku: string;
    title: string;
  }>;
}

export interface InquiryRequest {
  page: number;
}

export interface InquiryCreateResponse {
  success: boolean;
  message: string;
  code: number;
  data: InquiryModel;
}

export interface InquirySearchResponse {
  success: boolean;
  message: string;
  code: number;
  data: InquiryModel[];
}

export interface InquiryResponse {
  success: boolean;
  message: string;
  code: number;
  data: {
    count: number;
    inquiries: InquiryModel[];
  };
}

export interface InquiryVersionsResponse {
  success: boolean;
  message: string;
  code: number;
  data: InquiryModel[];
}

export const StoreInquiry = (createPayload: InquiryCreateRequest): Promise<InquirySearchResponse> =>
  httpApi.post<InquirySearchResponse>('inquiries/create', createPayload).then(({ data }) => data);

export const Inquiries = (listPayload: InquiryRequest): Promise<InquiryResponse> =>
  httpApi.get<InquiryResponse>('inquiries?page=' + listPayload.page).then(({ data }) => data);

export const TodayInquiries = (listPayload: InquiryRequest): Promise<InquiryResponse> =>
  httpApi.get<InquiryResponse>('inquiries/today?page=' + listPayload.page).then(({ data }) => data);

export const SearchInquiry = (searchPayload: InquirySearchRequest): Promise<InquirySearchResponse> =>
  httpApi.post<InquirySearchResponse>('inquiries/search', searchPayload).then(({ data }) => data);

export const Versions = (id: number): Promise<InquiryVersionsResponse> =>
  httpApi.get<InquiryVersionsResponse>('inquiries/versions/' + id).then(({ data }) => data);

export const StoreInquiryVersion = (createPayload: InquiryVersionCreateRequest): Promise<InquiryCreateResponse> =>
  httpApi
    .post<InquiryCreateResponse>('inquiries/versions/create/' + createPayload.inquiry, createPayload)
    .then(({ data }) => data);
