import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './CategoriesPage.style';
import { notificationController } from '@app/controllers/notificationController';
import { Categories, CategoryUpdateRequest, CreateCategory, UpdateCategory } from '@app/api/Category.api';
import { CategoryData, CategoryTableColumns } from '@app/interfaces/category.interface';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { CategoryModel } from '@app/domain/CategoryModel';
import { TablePagination } from '@app/interfaces/table.interface';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { InboxOutlined } from '@ant-design/icons';

export let initValues: CategoryUpdateRequest = {
  title: '',
  status: '',
  thumbnail: undefined,
  category: '0',
  categoryTitle: '---',
  type: 'product',
};

const CategoriesPage: React.FC = () => {
  const { t } = useTranslation();

  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [allCategories, setAllCategories] = useState<CategoryModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [category, setCategory] = useState<CategoryModel>();
  const [pagination, setPagination] = useState<TablePagination>({ current: 1, pageSize: 10, total: 1 });
  const [thumbnailFile, setThumbnailFile] = useState<File>();

  const handleOpenCategory = (category: CategoryModel) => {
    setEditModal(true);
    setCategory(category);

    initValues = {
      title: category?.title ?? '',
      status: category?.status ?? '',
      thumbnail: thumbnailFile ?? undefined,
      category: category.category?.id.toString() ?? '0',
      categoryTitle: category.category?.title ?? '---',
      type: 'product',
    };
  };

  const getCategories = () => {
    setCategories([]);
    Categories({ type: 'product', page: pagination.current })
      .then((result) => {
        if (result.success) {
          result.data.categories.map((category, index) => {
            setAllCategories(result.data.allCategories ?? []);

            const _categories: CategoryData[] = [];
            category.categories.map((_category, _index) => {
              _categories.push({
                index: _category.id,
                thumbnail: <img src={_category.thumbnail} width="50" />,
                title: _category.title,
                mainCategory: _category.category ? _category.category.title : '---',
                status:
                  _category.status === 'active' ? (
                    <span className="-status-success">{_category.status}</span>
                  ) : (
                    <span className="label label-danger">{_category.status}</span>
                  ),
                actions: (
                  <BaseSpace>
                    <BaseButton type="ghost" onClick={() => handleOpenCategory(_category)}>
                      {t('common.update')}
                    </BaseButton>
                  </BaseSpace>
                ),
              });
            });

            setCategories((categories) => [
              ...categories,
              {
                index: category.id,
                thumbnail: <img src={category.thumbnail} width="50" />,
                title: category.title,
                mainCategory: category.category ? category.category.title : '---',
                status:
                  category.status === 'active' ? (
                    <span className="-status-success">{category.status}</span>
                  ) : (
                    <span className="label label-danger">{category.status}</span>
                  ),
                actions: (
                  <BaseSpace>
                    <BaseButton type="ghost" onClick={() => handleOpenCategory(category)}>
                      {t('common.update')}
                    </BaseButton>
                  </BaseSpace>
                ),
                children: _categories,
              },
            ]);
          });

          setPagination({
            current: pagination.current,
            pageSize: 10,
            total: result.data.count,
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleTableChange = (pagination: TablePagination) => {
    setPagination({
      current: pagination.current++,
      pageSize: pagination.pageSize,
      total: pagination.total,
    });
    getCategories();
  };

  const uploadProps = {
    name: 'thumbnail',
    multiple: false,
    accept: '*.png',
    onChange: (info: any) => {
      setThumbnailFile(info.file.originFileObj);
    },
  };

  const handleSubmit = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('title', initValues.title);
    formData.append('category', initValues.category);
    formData.append('status', initValues.status);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append('thumbnail', thumbnailFile);
    formData.append('type', initValues.type);

    UpdateCategory(formData, category?.id)
      .then((result) => {
        if (result.success) {
          getCategories();
          notificationController.success({ message: result.message });
          setEditModal(false);
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  const handleSubmitCreate = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('title', initValues.title);
    formData.append('category', initValues.category);
    formData.append('status', initValues.status);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append('thumbnail', thumbnailFile);
    formData.append('type', initValues.type);

    CreateCategory(formData)
      .then((result) => {
        if (result.success) {
          getCategories();
          notificationController.success({ message: result.message });
          setCreateModal(false);
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  return (
    <>
      <S.TablesWrapper>
        <S.Card title={t('categories.categories')} padding="1.25rem 1.25rem 0">
          <BaseButton
            type="primary"
            onClick={() => {
              setCreateModal(true);
              initValues = {
                title: '',
                status: 'active',
                thumbnail: undefined,
                category: '0',
                categoryTitle: '---',
                type: 'product',
              };
            }}
          >
            {t('categories.create')}
          </BaseButton>
          <S.Table
            scroll={{ x: 800 }}
            columns={CategoryTableColumns(t)}
            dataSource={categories}
            loading={loading}
            pagination={pagination}
            onChange={() => handleTableChange(pagination)}
          />
        </S.Card>
      </S.TablesWrapper>

      <BaseModal
        title={t('categories.update')}
        visible={editModal}
        centered
        size="large"
        onOk={handleSubmit}
        onCancel={() => setEditModal(false)}
        cancelText={t('common.close')}
        okText={t('common.update')}
      >
        {category ? (
          <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
            <S.FormItem
              name="title"
              label={t('categories.title')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.FormInput
                placeholder={t('categories.title')}
                onChange={(target) => (initValues.title = target.target.value)}
              />
            </S.FormItem>

            <S.FormItem name="categoryTitle" label={t('categories.main-category')}>
              <BaseSelect width={120} onChange={(value: any) => (initValues.category = value)}>
                {allCategories
                  .filter((item) => item.id != category.id)
                  .map((_category, _index) => (
                    <Option
                      key={_index}
                      value={_category.id}
                      selected={_category.id.toString() === initValues.category}
                    >
                      {_category.title}
                    </Option>
                  ))}
              </BaseSelect>
            </S.FormItem>

            <S.FormItem
              name="status"
              label={t('categories.status')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <BaseSelect width={120} onChange={(value: any) => (initValues.status = value)}>
                <Option value="active">{t('common.active')}</Option>
                <Option value="deactivate">{t('common.deactivate')}</Option>
              </BaseSelect>
            </S.FormItem>

            <S.FormItem name="thumbnail" label={t('categories.thumbnail')}>
              <BaseUpload.Dragger {...uploadProps}>
                <S.DraggerIconWrapper>
                  <InboxOutlined />
                </S.DraggerIconWrapper>
                <S.DraggerTitle>{t('uploads.dragUpload')}</S.DraggerTitle>
              </BaseUpload.Dragger>
            </S.FormItem>
          </BaseForm>
        ) : null}
      </BaseModal>

      <BaseModal
        title={t('categories.update')}
        visible={createModal}
        centered
        size="large"
        onOk={handleSubmitCreate}
        onCancel={() => setCreateModal(false)}
        cancelText={t('common.close')}
        okText={t('common.create')}
      >
        <BaseForm layout="vertical" onFinish={handleSubmitCreate} requiredMark="optional" initialValues={initValues}>
          <S.FormItem
            name="title"
            label={t('categories.title')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <S.FormInput
              placeholder={t('categories.title')}
              onChange={(target) => (initValues.title = target.target.value)}
            />
          </S.FormItem>

          <S.FormItem name="categoryTitle" label={t('categories.main-category')}>
            <BaseSelect width={120} onChange={(value: any) => (initValues.category = value)}>
              {allCategories.map((_category, _index) => (
                <Option key={_index} value={_category.id}>
                  {_category.title}
                </Option>
              ))}
            </BaseSelect>
          </S.FormItem>

          <S.FormItem
            name="status"
            label={t('categories.status')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <BaseSelect width={120} onChange={(value: any) => (initValues.status = value)}>
              <Option value="active">{t('common.active')}</Option>
              <Option value="deactivate">{t('common.deactivate')}</Option>
            </BaseSelect>
          </S.FormItem>

          <S.FormItem name="thumbnail" label={t('categories.thumbnail')}>
            <BaseUpload.Dragger {...uploadProps}>
              <S.DraggerIconWrapper>
                <InboxOutlined />
              </S.DraggerIconWrapper>
              <S.DraggerTitle>{t('uploads.dragUpload')}</S.DraggerTitle>
            </BaseUpload.Dragger>
          </S.FormItem>
        </BaseForm>
      </BaseModal>
    </>
  );
};
export default CategoriesPage;
