export const UserSearchTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('users.firstname'),
    dataIndex: 'firstname',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('users.lastname'),
    dataIndex: 'lastname',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('users.email'),
    dataIndex: 'email',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('common.action'),
    dataIndex: 'action',
    width: '15%',
  },
];

export const UserSearchTableColumns2 = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('users.firstname'),
    dataIndex: 'firstName',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('users.lastname'),
    dataIndex: 'lastName',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('users.email'),
    dataIndex: 'email',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('common.action'),
    dataIndex: 'action',
    width: '15%',
  },
];

export const UserAddressTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('users.firstname'),
    dataIndex: 'firstname',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('users.lastname'),
    dataIndex: 'lastname',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('users.address'),
    dataIndex: 'address',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('users.city'),
    dataIndex: 'city',
    showSorterTooltip: true,
    editable: true,
  },
  {
    title: t('common.action'),
    dataIndex: 'action',
    width: '15%',
  },
];

export interface UserSearchData {
  index: number;
  firstname: string;
  lastname: string;
  email: string;
  action: JSX.Element;
}

export interface UserSearchData2 {
  index: number;
  firstName: string;
  lastName: string;
  email: string;
  action: JSX.Element;
}

export interface UserAddressData {
  index: number;
  firstname: string;
  lastname: string;
  city: string;
  address: string;
  action: JSX.Element;
}
