import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/Auth/Login';
import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import DashboardPage from '@app/pages/Dashboard/DashboardPage';
import CategoriesPage from '@app/pages/Products/categories/CategoriesPage';
import NewAttributePage from '@app/pages/Products/attributes/NewAttributePage';
import AttributePage from '@app/pages/Products/attributes/AttributePage';
import UpdateAttributePage from '@app/pages/Products/attributes/UpdateAttributePage';
import NewVariationPage from '@app/pages/Products/variations/NewVariationPage';
import VariationPage from '@app/pages/Products/variations/VariationPage';
import UpdateVariationPage from '@app/pages/Products/variations/UpdateVariationPage';
import NewBrandPage from '@app/pages/Products/brands/NewBrandPage';
import BrandPage from '@app/pages/Products/brands/BrandPage';
import UpdateBrandPage from '@app/pages/Products/brands/UpdateBrandPage';
import NewCountryPage from '@app/pages/Settings/Countries/NewCountryPage';
import CountryPage from '@app/pages/Settings/Countries/CountryPage';
import UpdateCountryPage from '@app/pages/Settings/Countries/UpdateCountryPage';
import StatePage from '@app/pages/Settings/Countries/States/StatePage';
import CityPage from '@app/pages/Settings/Countries/Cities/CityPage';
import NewProductPage from '@app/pages/Products/newPage';
import NewInquiryPage from '@app/pages/Inquiries/NewInquiryPage';
import InquiryPage from '@app/pages/Inquiries/InquiryPage';
import ShowInquiryPage from '@app/pages/Inquiries/ShowInquiryPage';
import InquiryVersionsPage from '@app/pages/Inquiries/versions/VreionPage';
import InquiryNewVersionPage from '@app/pages/Inquiries/versions/NewVersionPage';
import TodayInquiryPage from '@app/pages/Inquiries/TodayInquiryPage';
import SearchInquiryPage from '@app/pages/Inquiries/SearchInquirytPage';
import NewInvoicePage from '@app/pages/Invoices/NewInvoicePage';
import ImportInquiryPage from '@app/pages/Invoices/ImportInquiryPage';
import NewImportInvoicePage from '@app/pages/Invoices/NewImportInvoicePage';
import InvoicePage from '@app/pages/Invoices/InvoicePage';
import ShowInvoicePage from '@app/pages/Invoices/ShowInvoicePage';
import TodayInvoicePage from '@app/pages/Invoices/TodayInvoicePage';
import InvoiceVersionPage from '@app/pages/Invoices/InvoiceVersionsPage';
import NewInvoiceVersionPage from '@app/pages/Invoices/NewInvoiceVersionPage';
import SearchInvoicePage from '@app/pages/Invoices/SearchInvoicePage';
import NewOrderPage from '@app/pages/Orders/NewOrderPage';
import { ProductList } from '@app/pages/Products/ProductList';
import { SingleProduct } from '@app/pages/Products/SingleProduct';

const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-Dashboard';

const Dashboard = withLoading(DashboardPage);
const Products = withLoading(ProductList);
const SingleProductPage = withLoading(SingleProduct);
const ProductCategories = withLoading(CategoriesPage);
const ProductNewAttribute = withLoading(NewAttributePage);
const ProductAttributes = withLoading(AttributePage);
const UpdateProductAttribute = withLoading(UpdateAttributePage);
const ProductNewVariationPage = withLoading(NewVariationPage);
const ProductVariationPage = withLoading(VariationPage);
const ProductUpdateVariationPage = withLoading(UpdateVariationPage);
const ProductNewBrandPage = withLoading(NewBrandPage);
const ProductBrandPage = withLoading(BrandPage);
const ProductUpdateBrandPage = withLoading(UpdateBrandPage);
const ProductNewProductPage = withLoading(NewProductPage);

const InquiryNewPage = withLoading(NewInquiryPage);
const InquiriesPage = withLoading(InquiryPage);
const ShowInquiryInfoPage = withLoading(ShowInquiryPage);
const InquiriesVersionsPage = withLoading(InquiryVersionsPage);
const InquiriesNewVersionsPage = withLoading(InquiryNewVersionPage);
const TodayInquiriesPage = withLoading(TodayInquiryPage);
const SearchInquiriesPage = withLoading(SearchInquiryPage);

const InvoicesPage = withLoading(InvoicePage);
const InvoiceNewPage = withLoading(NewInvoicePage);
const InvoiceImportInquiryNewPage = withLoading(ImportInquiryPage);
const InvoiceNewImportInquiryNewPage = withLoading(NewImportInvoicePage);
const ShowInvoiceNewPage = withLoading(ShowInvoicePage);
const TodayInvoiceNewPage = withLoading(TodayInvoicePage);
const InvoiceVersionsPage = withLoading(InvoiceVersionPage);
const NewInvoiceVersionsPage = withLoading(NewInvoiceVersionPage);
const SearchInvoicesPage = withLoading(SearchInvoicePage);

const NewOrdersPage = withLoading(NewOrderPage);

const SettingNewCountry = withLoading(NewCountryPage);
const SettingCountryList = withLoading(CountryPage);
const SettingUpdateCountryList = withLoading(UpdateCountryPage);
const SettingStateList = withLoading(StatePage);
const SettingCityList = withLoading(CityPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const ProtectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={ProtectedLayout}>
          <Route index element={<Dashboard />} />

          <Route path="products">
            <Route path="" element={<Products />} />
            <Route path=":uuid" element={<SingleProductPage />} />
            <Route path="categories" element={<ProductCategories />} />

            <Route path="new" element={<ProductNewProductPage />} />

            <Route path="attributes">
              <Route path="" element={<ProductAttributes />} />
              <Route path="new" element={<ProductNewAttribute />} />
              <Route path=":uuid" element={<UpdateProductAttribute />} />
            </Route>

            <Route path="variations">
              <Route path="" element={<ProductVariationPage />} />
              <Route path="new" element={<ProductNewVariationPage />} />
              <Route path=":uuid" element={<ProductUpdateVariationPage />} />
            </Route>

            <Route path="brands">
              <Route path="" element={<ProductBrandPage />} />
              <Route path="new" element={<ProductNewBrandPage />} />
              <Route path=":uuid" element={<ProductUpdateBrandPage />} />
            </Route>
          </Route>
          <Route path="inquiries">
            <Route path="" element={<InquiriesPage />} />
            <Route path="new" element={<InquiryNewPage />} />
            <Route path="today" element={<TodayInquiriesPage />} />
            <Route path="search" element={<SearchInquiriesPage />} />
            <Route path=":uuid" element={<ShowInquiryInfoPage />} />
            <Route path=":uuid/versions">
              <Route path="" element={<InquiriesVersionsPage />} />
              <Route path="create" element={<InquiriesNewVersionsPage />} />
            </Route>
          </Route>
          <Route path="pre-invoices">
            <Route path="" element={<InvoicesPage />} />
            <Route path="new" element={<InvoiceNewPage />} />
            <Route path="new/inquiry/import" element={<InvoiceImportInquiryNewPage />} />
            <Route path="new/inquiry" element={<InvoiceNewImportInquiryNewPage />} />
            <Route path="today" element={<TodayInvoiceNewPage />} />
            <Route path="search" element={<SearchInvoicesPage />} />
            <Route path=":uuid" element={<ShowInvoiceNewPage />} />
            <Route path=":uuid/versions">
              <Route path="" element={<InvoiceVersionsPage />} />
              <Route path="create" element={<NewInvoiceVersionsPage />} />
            </Route>
          </Route>
          <Route path="orders">
            <Route path="new" element={<NewOrdersPage />} />
          </Route>
          <Route path="settings">
            <Route path="countries">
              <Route path="" element={<SettingCountryList />} />
              <Route path="new" element={<SettingNewCountry />} />
              <Route path=":uuid" element={<SettingUpdateCountryList />} />

              <Route path=":uuid/states">
                <Route path="" element={<SettingStateList />} />
              </Route>
              <Route path=":uuid/states/:uuid/cities">
                <Route path="" element={<SettingCityList />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
