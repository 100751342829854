import { CategoryModel } from '@app/domain/CategoryModel';
import { httpApi } from '@app/api/http.api';

export interface CategoryRequest {
  type: string;
  page: number;
}

export interface CategoryResponse {
  success: boolean;
  message: string;
  code: number;
  data: {
    allCategories: CategoryModel[];
    categories: CategoryModel[];
    count: number;
  };
}

export interface CategoryUpdateRequest {
  type: string;
  title: string;
  status: string;
  category: string;
  categoryTitle?: string;
  thumbnail?: File;
}

export const Categories = (categoryPayload: CategoryRequest): Promise<CategoryResponse> =>
  httpApi
    .get<CategoryResponse>('categories/' + categoryPayload.type + '?page=' + categoryPayload.page)
    .then(({ data }) => data);

export const UpdateCategory = (categoryPayload: any, id: number | undefined): Promise<CategoryResponse> =>
  httpApi.post<CategoryResponse>('categories/update/' + id, categoryPayload).then(({ data }) => data);

export const CreateCategory = (categoryPayload: any): Promise<CategoryResponse> =>
  httpApi.post<CategoryResponse>('categories/create', categoryPayload).then(({ data }) => data);
