import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './CountryPage.style';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { CountryInterface } from '@app/interfaces/country.interface';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { InboxOutlined } from '@ant-design/icons';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { StoreCountry } from '@app/api/Country.api';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';

export const initValues: CountryInterface = {
  title: '',
  code: '',
  flag: '',
  currency: '',
  status: '',
  latitude: '',
  longitude: '',
  timezone: '',
};

const NewCountryPage: React.FC = () => {
  const { t } = useTranslation();
  const [flag, setFlag] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<{ lat: number; lng: number }>({ lat: 0.0, lng: 0.0 });
  const navigate = useNavigate();

  const handleSubmitCreate = (values: CountryInterface) => {
    const formData = new FormData();
    formData.append('title', initValues.title);
    formData.append('code', initValues.code);
    formData.append('status', initValues.status);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append('flag', flag);
    formData.append('currency', initValues.currency);
    formData.append('latitude', location.lat.toString());
    formData.append('longitude', location.lng.toString());
    formData.append('timezone', initValues.timezone);

    StoreCountry(formData)
      .then((result) => {
        if (result.success) {
          notificationController.success({ message: result.message });
          navigate('/settings/countries');
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  const uploadProps = {
    name: 'thumbnail',
    multiple: false,
    accept: '*.png',
    onChange: (info: any) => {
      setFlag(info.file.originFileObj);
    },
  };

  const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey: '' });

  if (loadError) {
    return <>{loadError.message}</>;
  }

  const handleSetLocation = (event: any) => {
    setLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };

  return (
    <>
      <S.Card title={t('countries.new-country')} padding="1.25rem 1.25rem 0">
        <BaseForm
          style={{ width: '70%', margin: '0 auto 0 auto' }}
          layout="vertical"
          onFinish={handleSubmitCreate}
          requiredMark="optional"
          initialValues={initValues}
        >
          <S.InputsWrapper>
            <S.FormItem
              style={{ width: '100%' }}
              name="title"
              label={t('countries.title')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.FormInput
                placeholder={t('countries.title')}
                onChange={(target) => (initValues.title = target.target.value)}
              />
            </S.FormItem>
            <S.FormItem
              style={{ width: '100%' }}
              name="code"
              label={t('countries.country-code')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.FormInput
                placeholder={t('countries.country-code')}
                onChange={(target) => (initValues.code = target.target.value)}
              />
            </S.FormItem>
          </S.InputsWrapper>
          <S.FormItem name="thumbnail" label={t('countries.flag')}>
            <BaseUpload.Dragger {...uploadProps}>
              <S.DraggerIconWrapper>
                <InboxOutlined />
              </S.DraggerIconWrapper>
              <S.DraggerTitle>{t('uploads.dragUpload')}</S.DraggerTitle>
            </BaseUpload.Dragger>
          </S.FormItem>
          <S.InputsWrapper>
            <S.FormItem
              style={{ width: '100%' }}
              name="currency"
              label={t('countries.currency')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <S.FormInput
                placeholder={t('countries.currency')}
                onChange={(target) => (initValues.currency = target.target.value)}
              />
            </S.FormItem>
            <S.FormItem
              style={{ width: '100%' }}
              name="status"
              label={t('countries.status')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <BaseSelect width={120} onChange={(value: any) => (initValues.status = value)}>
                <Option value="active">{t('common.active')}</Option>
                <Option value="deactivate">{t('common.deactivate')}</Option>
              </BaseSelect>
            </S.FormItem>
          </S.InputsWrapper>
          <S.FormItem
            name="timezone"
            label={t('countries.timezone')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <S.FormInput
              placeholder={t('countries.timezone')}
              onChange={(target) => (initValues.timezone = target.target.value)}
            />
          </S.FormItem>
          <S.MapsCard title={t('countries.location')}>
            {isLoaded ? (
              <GoogleMap
                center={{
                  lat: location.lat,
                  lng: location.lng,
                }}
                zoom={14}
                mapContainerStyle={{ height: '100%', width: '100%' }}
                onClick={handleSetLocation}
              >
                <Marker position={{ lat: location.lat, lng: location.lng }} />
              </GoogleMap>
            ) : (
              <></>
            )}
          </S.MapsCard>
          <S.InputsWrapper style={{ marginTop: '50px' }}>
            <S.SubmitButton type="primary" htmlType="submit" loading={loading}>
              {t('common.submit')}
            </S.SubmitButton>
          </S.InputsWrapper>
        </BaseForm>
      </S.Card>
    </>
  );
};

export default NewCountryPage;
