export interface CountryInterface {
  title: string;
  code: string;
  flag: string;
  currency: string;
  status: string;
  latitude: string;
  longitude: string;
  timezone: string;
}

export interface StateInterface {
  id?: number;
  title: string;
  code: string;
  status: string;
  latitude: string;
  longitude: string;
}

export interface CountryData {
  index: number;
  flag: JSX.Element;
  title: string;
  status: string;
  actions: JSX.Element;
}

export interface StateData {
  index: number;
  title: string;
  status: string;
  actions: JSX.Element;
}

export interface StateRequest {
  title: string;
  status: string;
  code: string;
  latitude: string;
  longitude: string;
}

export const CountryTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('countries.flag'),
    dataIndex: 'flag',
    showSorterTooltip: true,
  },
  {
    title: t('countries.name'),
    dataIndex: 'title',
    showSorterTooltip: true,
  },
  {
    title: t('countries.status'),
    dataIndex: 'status',
    showSorterTooltip: true,
  },
  {
    title: t('countries.actions'),
    dataIndex: 'actions',
    width: '15%',
  },
];

export const StateTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('countries.name'),
    dataIndex: 'title',
    showSorterTooltip: true,
  },
  {
    title: t('countries.status'),
    dataIndex: 'status',
    showSorterTooltip: true,
  },
  {
    title: t('countries.actions'),
    dataIndex: 'actions',
    width: '15%',
  },
];
