import { httpApi } from '@app/api/http.api';
import { BrandModel } from '@app/domain/Brand.model';

export interface BrandRequest {
  page: number;
}

export interface BrandResponse {
  success: boolean;
  message: string;
  code: number;
  data: {
    brands: BrandModel[];
    count: number;
  };
}

export interface BrandCreateResponse {
  success: boolean;
  message: string;
  code: number;
  data: BrandModel;
}

export const StoreBrand = (brandPayload: any): Promise<BrandResponse> =>
  httpApi.post<BrandResponse>('products/brands/create', brandPayload).then(({ data }) => data);

export const Brands = (brandPayload?: BrandRequest): Promise<BrandResponse> =>
  httpApi.get<BrandResponse>('products/brands?page=' + brandPayload?.page).then(({ data }) => data);

export const UpdateBrand = (id: number, brandPayload: any): Promise<BrandResponse> =>
  httpApi.post<BrandResponse>('products/brands/update/' + id, brandPayload).then(({ data }) => data);
