export const CategoryTableColumns = (t: any) => [
  {
    title: '#',
    dataIndex: 'index',
    showSorterTooltip: true,
  },
  {
    title: t('thumbnail'),
    dataIndex: 'thumbnail',
    showSorterTooltip: true,
  },
  {
    title: t('categories.title'),
    dataIndex: 'title',
    showSorterTooltip: true,
  },
  {
    title: t('categories.main-category'),
    dataIndex: 'mainCategory',
    showSorterTooltip: true,
  },
  {
    title: t('categories.status'),
    dataIndex: 'status',
    showSorterTooltip: true,
  },
  {
    title: t('categories.actions'),
    dataIndex: 'actions',
    width: '15%',
  },
];

export interface CategoryData {
  index: number;
  thumbnail: JSX.Element;
  title: string;
  mainCategory: string;
  status: JSX.Element;
  actions: JSX.Element;
  children?: CategoryData[];
}
