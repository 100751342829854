import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login, LoginRequest } from '@app/api/Auth.api';
import { setUser } from '@app/store/slices/userSlice';
import { deleteToken, deleteUser, persistToken, readToken } from '@app/services/localStorage.service';

export interface AuthSlice {
  token?: string | undefined | null;
  message?: string | undefined | null;
}

const initialState: AuthSlice = {
  token: readToken(),
  message: '',
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((res) => {
    if (res.success) {
      dispatch(setUser(res.data));
      persistToken(res.data.token);

      return {
        token: res.data.token,
        message: res.message,
      };
    } else {
      return {
        token: '',
        message: res.message,
      };
    }
  }),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.message = action.payload.message;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
      state.message = '';
    });
  },
});

export default authSlice.reducer;
