import { httpApi } from '@app/api/http.api';
import { BrandModel } from '@app/domain/Brand.model';
import { CountryModel, StateModal } from '@app/domain/Country.model';
import { StateRequest } from '@app/interfaces/country.interface';

export interface CountryRequest {
  page: number;
}

export interface CountryResponse {
  success: boolean;
  message: string;
  code: number;
  data: CountryModel[];
}

export interface StateResponse {
  success: boolean;
  message: string;
  code: number;
  data: StateModal[];
}

export const StoreCountry = (countryPayload: any): Promise<CountryResponse> =>
  httpApi.post<CountryResponse>('countries/create', countryPayload).then(({ data }) => data);

export const Countries = (): Promise<CountryResponse> =>
  httpApi.get<CountryResponse>('countries/all').then(({ data }) => data);

export const UpdateCountry = (id: number, countryPayload: any): Promise<CountryResponse> =>
  httpApi.post<CountryResponse>('countries/update/' + id, countryPayload).then(({ data }) => data);

export const States = (id: number): Promise<StateResponse> =>
  httpApi.get<StateResponse>('countries/all/' + id + '/state').then(({ data }) => data);

export const StoreState = (id: number, statePayload: StateRequest): Promise<StateResponse> =>
  httpApi.post<StateResponse>('countries/create/' + id + '/state', statePayload).then(({ data }) => data);

export const UpdateState = (id: number, statePayload: StateRequest): Promise<StateResponse> =>
  httpApi.post<StateResponse>('countries/update/' + id + '/state', statePayload).then(({ data }) => data);

export const Cities = (id: number): Promise<StateResponse> =>
  httpApi.get<StateResponse>('countries/all/' + id + '/city').then(({ data }) => data);

export const StoreCity = (id: number, statePayload: StateRequest): Promise<StateResponse> =>
  httpApi.post<StateResponse>('countries/create/' + id + '/city', statePayload).then(({ data }) => data);

export const UpdateCity = (id: number, statePayload: StateRequest): Promise<StateResponse> =>
  httpApi.post<StateResponse>('countries/update/' + id + '/city', statePayload).then(({ data }) => data);
