import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '@app/pages/Settings/Countries/CountryPage.style';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { InvoiceData, InvoiceTableColumns } from '@app/interfaces/invoice.interface';
import { InvoiceModel } from '@app/domain/invoice.model';
import { SearchInquiry } from '@app/api/Inquiry.api';
import { SearchInvoices } from '@app/api/Invoice.api';

export const initValues = {
  code: '',
  fromDate: '',
  toDate: '',
  email: '',
};

const SearchInvoicePage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<InvoiceData[]>([]);
  const navigate = useNavigate();

  const handleOpenShow = (invoice: InvoiceModel) => {
    navigate('/pre-invoices/' + invoice.uuid, { state: invoice });
  };

  const handleOpenVersions = (invoice: InvoiceModel) => {
    navigate('/pre-invoices/' + invoice.uuid + '/versions', { state: invoice });
  };

  const handleSubmitSearch = (values: any) => {
    setInvoices([]);
    SearchInvoices({
      email: values.email,
      code: values.code,
      fromDate: values.fromDate,
      toDate: values.toDate,
    })
      .then((result) => {
        if (result.success) {
          result.data.map((invoice, index) => {
            setInvoices((invoices) => {
              return [
                ...invoices,
                {
                  id: invoice.id,
                  index: invoice.id,
                  code: invoice.code,
                  status: invoice.status,
                  version: invoice.version,
                  createdAt: moment(invoice.createdAt).format('Y-M-D h:i:s'),
                  totalPrice: invoice.totalPrice,
                  user: invoice.user.email,
                  actions: (
                    <BaseSpace>
                      <BaseButton type="ghost" onClick={() => handleOpenShow(invoice)}>
                        {t('common.show')}
                      </BaseButton>
                      <BaseButton type="ghost" onClick={() => handleOpenVersions(invoice)}>
                        {t('invoices.versions')}
                      </BaseButton>
                    </BaseSpace>
                  ),
                },
              ];
            });
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  };

  return (
    <>
      <S.Card title={t('invoices.search')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <BaseForm
            style={{ width: '100%', margin: '0 auto 0 auto' }}
            layout="vertical"
            onFinish={handleSubmitSearch}
            requiredMark="optional"
            initialValues={initValues}
          >
            <S.InputsWrapper>
              <S.FormItem
                style={{ width: '100%' }}
                name="code"
                label={t('invoices.code')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <S.FormInput
                  placeholder={t('invoices.code')}
                  onChange={(target) => (initValues.code = target.target.value)}
                />
              </S.FormItem>
              <S.FormItem
                style={{ width: '100%' }}
                name="email"
                label={t('invoices.email')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <S.FormInput
                  placeholder={t('invoices.email')}
                  onChange={(target) => (initValues.email = target.target.value)}
                />
              </S.FormItem>
            </S.InputsWrapper>
            <S.InputsWrapper>
              <S.FormItem
                style={{ width: '100%' }}
                name="fromDate"
                label={t('invoices.fromDate')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <DayjsDatePicker
                  format="L"
                  style={{ width: '100%' }}
                  onChange={(target: any) => console.log(target.date)}
                />
              </S.FormItem>
              <S.FormItem
                style={{ width: '100%' }}
                name="toDate"
                label={t('invoices.toDate')}
                rules={[{ required: false, message: t('common.requiredField') }]}
              >
                <DayjsDatePicker
                  format="L"
                  style={{ width: '100%' }}
                  onChange={(target: any) => console.log(target.date)}
                />
              </S.FormItem>
            </S.InputsWrapper>

            <S.InputsWrapper style={{ marginBottom: '50px' }}>
              <S.SubmitButton type="primary" htmlType="submit" loading={loading}>
                {t('common.search')}
              </S.SubmitButton>
            </S.InputsWrapper>
          </BaseForm>

          <S.Table scroll={{ x: 800 }} columns={InvoiceTableColumns(t)} dataSource={invoices} loading={loading} />
        </S.TablesWrapper>
      </S.Card>
    </>
  );
};
export default SearchInvoicePage;
