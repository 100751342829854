import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '@app/pages/Settings/Countries/CountryPage.style';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { InquiryModel } from '@app/domain/Inquiry.model';
import { Inquiries, TodayInquiries } from '@app/api/Inquiry.api';
import { TablePagination } from '@app/interfaces/table.interface';
import { InquiryData, InquiryTableColumns } from '@app/interfaces/inquiry.interface';
import moment from 'moment';

const TodayInquiryPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [inquiries, setInquiries] = useState<InquiryData[]>([]);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<TablePagination>({ current: 1, pageSize: 10, total: 1 });

  useEffect(() => {
    setInquiries([]);
    TodayInquiries({ page: pagination.current })
      .then((result) => {
        if (result.success) {
          result.data.inquiries.map((inquiry, index) => {
            setInquiries((inquiries) => {
              return [
                ...inquiries,
                {
                  id: inquiry.id,
                  index: inquiry.id,
                  code: inquiry.code,
                  status: inquiry.status,
                  version: inquiry.version,
                  totalPrice: inquiry.totalPrice,
                  createdAt: moment(inquiry.createdAt).format('Y-M-D h:i:s'),
                  user: inquiry.user ? inquiry.user.email : inquiry.email,
                  actions: (
                    <BaseSpace>
                      <BaseButton type="ghost" onClick={() => handleOpenShow(inquiry)}>
                        {t('common.show')}
                      </BaseButton>
                      <BaseButton type="ghost" onClick={() => handleOpenVersions(inquiry)}>
                        {t('inquiries.versions')}
                      </BaseButton>
                    </BaseSpace>
                  ),
                },
              ];
            });
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  }, []);

  const handleOpenShow = (inquiry: InquiryModel) => {
    console.log(inquiry);
    navigate('/inquiries/' + inquiry.uuid, { state: inquiry });
  };

  const handleOpenVersions = (inquiry: InquiryModel) => {
    navigate('/inquiries/' + inquiry.uuid + '/versions', { state: inquiry });
  };

  return (
    <>
      <S.Card title={t('inquiries.inquiries')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <S.FormItem style={{ width: '100%' }} name="inquiries" label={t('inquiries.code')}>
            <S.FormInput
              placeholder={t('inquiries.code')}
              onChange={(target) => {
                setInquiries(inquiries.filter((item) => item.code.includes(target.target.value)));
              }}
            />
          </S.FormItem>

          <S.Table scroll={{ x: 800 }} columns={InquiryTableColumns(t)} dataSource={inquiries} loading={loading} />
        </S.TablesWrapper>
      </S.Card>
    </>
  );
};
export default TodayInquiryPage;
