import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from '@app/pages/Settings/Countries/CountryPage.style';
import { CountryData, CountryTableColumns } from '@app/interfaces/country.interface';
import { Countries } from '@app/api/Country.api';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import { CountryModel } from '@app/domain/Country.model';
import { useNavigate } from 'react-router-dom';

const CountryPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<CountryData[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setCountries([]);
    Countries()
      .then((result) => {
        if (result.success) {
          result.data.map((country, index) => {
            setCountries((countries) => [
              ...countries,
              {
                index: country.id,
                title: country.title,
                flag: <img src={country.flag} width={50} />,
                status: country.status,
                actions: (
                  <BaseSpace>
                    <BaseButton type="ghost" onClick={() => handleOpenUpdate(country)}>
                      {t('common.update')}
                    </BaseButton>
                    <BaseButton type="ghost" onClick={() => handleOpenStates(country)}>
                      {t('countries.states')}
                    </BaseButton>
                  </BaseSpace>
                ),
              },
            ]);
          });
        } else {
          notificationController.error({ message: result.message });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({ message: error.message });
      });
  }, []);

  const handleOpenUpdate = (country: CountryModel) => {
    navigate('/settings/countries/' + country.uuid, { state: country });
  };

  const handleOpenStates = (country: CountryModel) => {
    navigate('/settings/countries/' + country.uuid + '/states', { state: country });
  };

  return (
    <>
      <S.Card title={t('countries.countries')} padding="1.25rem 1.25rem 0">
        <S.TablesWrapper>
          <S.FormItem style={{ width: '100%' }} name="title" label={t('countries.name')}>
            <S.FormInput
              placeholder={t('countries.name')}
              onChange={(target) => {
                setCountries(countries.filter((item) => item.title.includes(target.target.value)));
              }}
            />
          </S.FormItem>

          <S.Table scroll={{ x: 800 }} columns={CountryTableColumns(t)} dataSource={countries} loading={loading} />
        </S.TablesWrapper>
      </S.Card>
    </>
  );
};
export default CountryPage;
